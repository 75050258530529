<template>
  <div class="flex xs:flex-col sm:flex-col flex-row">
    <a-upload
      name="avatar"
      method=""
      ref="ant_upload"
      :accept="accept"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      !openFileDialogOnClick
      @change="handleChange"
    >
      <img v-if="imageUrl" class="object-contain max-h-[25px] max-w-[25px]" :src="imageUrl" alt="avatar" />
      <div v-else class="flex flex-row w-full justify-center">
          <PlusOutlined :style="{fontSize: '20px'}" v-if="!isCamera"></PlusOutlined>
          <CameraOutlined :style="{fontSize: '20px'}" v-if="isCamera"></CameraOutlined>
          <div class="ant-upload-text font-bold">{{text}}</div>
      </div>
    </a-upload>
    <div v-if="this.isEmpty" class="text-red-500 w-full">
        Please insert image here
    </div>
  </div>
</template>
<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import { CameraOutlined } from '@ant-design/icons-vue';

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

export default {
    props:{
        componentId:{
            type: String,
            required: true,
        },
        text:{
            type: String,
            required:false,
        },
        accept:{
          type: String,
          required:true,
        },
        isCamera:{
          type: Boolean,
          required:false,
        }
    },
  data() {
    return {
      loading: false,
      imageUrl: '',
      isEmpty: false,
      exceed:false,
    };
  },
  components:{
    PlusOutlined,
    CameraOutlined
  },
  methods: {
    checkEmpty() {
    if(!this.imageUrl) {
       this.isEmpty = true;
    }else {
      this.isEmpty = false;
    }
  },
  async createImage (file) { // <= this must be asynchronous
            const reader = new FileReader();
            let image="";
            // console.log(file);
            // Wait for the data url to be loaded from the file
            const dataURL =  await new Promise(resolve => {
                reader.onload = (e) => resolve(e.target.result);
                reader.readAsDataURL(file);
            });

            // Wait for the image to be loaded
            const img = new Image();
            await new Promise(resolve => {
                img.onload = resolve;
                img.src = dataURL;
            });

            // Resize the image with a canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            
            // This is an addition to keep the aspect ratio of the image so it won't get distorted
            // See : https://stackoverflow.com/q/3971841/12594730
            const [maxWidth, maxHeight] = [480,480];
            const [imgWidth, imgHeight] = [
                img.naturalWidth,
                img.naturalHeight
            ];
            const ratio = Math.min(maxWidth / imgWidth, maxHeight / imgHeight);

            canvas.width = imgWidth * ratio;
            canvas.height = imgHeight * ratio;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL('image/jpeg', 0.2);
            return image;

      },
    async handleChange(info) {
      let checker=false;
      let that = this;
      // console.log(info.file.originFileObj.size/ 1024 / 1024);
      checker = that.beforeUpload(info.file.originFileObj);
      if(checker) {
          let files124 = await that.createImage(info.file.originFileObj);
          that.loading = false;
          // console.log(files124);
          that.imageUrl = files124;
          that.$emit('changed',{
                key: that.componentId,
                value:files124,
          });
      }else{
        that.$refs.ant_upload.value = null;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
       const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
        return false;
      }
      else if (!isLt2M) {
        this.$message.error('Image must smaller than 25MB!');
        return false;
      }
      else {
         return true;
      }
     
    },
  },
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 105px !important;
  height: 40px !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 3px;
  margin-left: 5px;
  font-size: 10px;
  color: #666;
}
.ant-upload.ant-upload-select-picture-card {
margin-right:0;
margin-bottom:0;
}
</style>